<script>
  export let scale = 1;
  export let color = "#323232";
</script>

<svg width={20 * scale} height={20 * scale} viewBox="-0.6 -0.3 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill={color} d="M1.33332 1.33333C1.14443 1.33333 0.986101 1.26944 0.858323 1.14167C0.730545 1.01389 0.666656 0.855556 0.666656 0.666667C0.666656 0.477778 0.730545 0.319444 0.858323 0.191667C0.986101 0.0638889 1.14443 0 1.33332 0H7.99999C8.18888 0 8.34721 0.0638889 8.47499 0.191667C8.60277 0.319444 8.66666 0.477778 8.66666 0.666667C8.66666 0.855556 8.60277 1.01389 8.47499 1.14167C8.34721 1.26944 8.18888 1.33333 7.99999 1.33333H1.33332ZM1.33332 4C1.14443 4 0.986101 3.93611 0.858323 3.80833C0.730545 3.68056 0.666656 3.52222 0.666656 3.33333C0.666656 3.14444 0.730545 2.98611 0.858323 2.85833C0.986101 2.73056 1.14443 2.66667 1.33332 2.66667H7.99999C8.18888 2.66667 8.34721 2.73056 8.47499 2.85833C8.60277 2.98611 8.66666 3.14444 8.66666 3.33333C8.66666 3.52222 8.60277 3.68056 8.47499 3.80833C8.34721 3.93611 8.18888 4 7.99999 4H1.33332ZM1.33332 6.66667C1.14443 6.66667 0.986101 6.60278 0.858323 6.475C0.730545 6.34722 0.666656 6.18889 0.666656 6C0.666656 5.81111 0.730545 5.65278 0.858323 5.525C0.986101 5.39722 1.14443 5.33333 1.33332 5.33333H5.33332C5.52221 5.33333 5.68055 5.39722 5.80832 5.525C5.9361 5.65278 5.99999 5.81111 5.99999 6C5.99999 6.18889 5.9361 6.34722 5.80832 6.475C5.68055 6.60278 5.52221 6.66667 5.33332 6.66667H1.33332Z" />

  <g transform="scale(1.05) translate(-1.7 0)">
    <path fill={color} d="M10.3667 9.31667C10.1444 9.47222 9.91666 9.48333 9.68332 9.35C9.44999 9.21667 9.33332 9.01667 9.33332 8.75V5.91667C9.33332 5.65 9.44999 5.45 9.68332 5.31667C9.91666 5.18333 10.1444 5.19444 10.3667 5.35L12.5 6.78333C12.7 6.91667 12.8 7.1 12.8 7.33333C12.8 7.56667 12.7 7.75 12.5 7.88333L10.3667 9.31667Z" />
  </g>
</svg>

