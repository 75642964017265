export default {
  isDefaultForLanguage: true,
  listenToThisArticle: "Hør denne artikkelen",
  minutesSingularOrPlural: "{n} min",
  videoInWidget: "Video spilles av i widget",

  // These translations are used by aria-label.
  playAudio: "Spill av lyd",
  pauseAudio: "Sett lyden på pause",
  playbackTime: "Playback time",
  secondsSingular: "1 sekund",
  secondsPlural: "{n} sekunder",
  minutesSingular: "1 minutt",
  minutesPlural: "{n} minutter",
  outOfTotalTime: "av total tid",
  visitAdvert: "Besøk annonsen",
  visitBeyondWords: "Besøk BeyondWords",
  closeWidget: "Lukk widget",
  seekBack: "Søk tilbake",
  seekAhead: "Søk fremover",
  previousSegment: "Forrige segment",
  nextSegment: "Neste segment",
  previousTrack: "Forrige spor",
  nextTrack: "Neste spor",
  togglePlaylist: "Bytt spilleliste",
  visitSourceArticle: "Besøk kildeartikkelen",
  maximizeVideo: "Maksimer videoen",
  changePlaybackRate: "Endre avspillingshastighet",
  downloadAudio: "Last ned lyd",
};
