<script>
  export let scale = 1;
  export let color1 = "#933afb";
  export let color2 = "#fb3a41";

  let gradientId = `gradient-${Math.random()}`;
</script>

<svg width={16 * scale} height={16 * scale} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill="url(#{gradientId})" d="M4.51819 0C3.20323 0.00414605 2.06058 0.402167 1.23154 1.23171C0.401171 2.06225 0.00315099 3.20622 0 4.52301V4.55435C0.00613614 7.21612 1.61729 10.5771 4.523 13.4826C6.55223 15.5125 8.90619 16.989 11.1512 17.6401C11.9816 17.8809 12.7653 18 13.4871 18C14.8315 18 15.9609 17.5859 16.7732 16.7738C18.0215 15.5255 18.3298 13.5286 17.6401 11.1512C16.9891 8.90638 15.5126 6.55259 13.4826 4.52284C10.5769 1.61679 7.2161 0.00597031 4.55417 0H4.51819ZM11.5084 16.4099C9.46706 15.818 7.30764 14.4566 5.42849 12.577C1.70684 8.85563 0.199673 4.07507 2.13703 2.13737C2.7125 1.56207 3.53856 1.29058 4.51354 1.29058C6.82123 1.29058 9.9611 2.81235 12.5771 5.42851C14.4569 7.308 15.8176 9.46709 16.41 11.5083C16.9634 13.4166 16.7709 14.9649 15.8677 15.8681C15.302 16.4331 14.4843 16.72 13.4841 16.72C12.8859 16.72 12.222 16.6172 11.5084 16.4099Z" />
  <path fill="url(#{gradientId})" d="M11.1483 0.360687C8.90517 1.01235 6.55252 2.48946 4.52328 4.5188C0.166276 8.87725 -1.28019 14.2587 1.23026 16.7697C2.06293 17.6026 3.21138 18 4.5339 18C7.19718 18 10.5655 16.3873 13.4767 13.4752C17.8334 9.11694 19.28 3.73601 16.7702 1.22481C15.9583 0.413305 14.8303 0 13.4865 0C12.7642 0 11.9795 0.11951 11.1483 0.360687ZM2.14341 15.856C1.24354 14.9563 1.05288 13.4115 1.60627 11.5066C2.19817 9.468 3.55852 7.31101 5.43692 5.43222C7.31433 3.55376 9.47084 2.19334 11.5085 1.60093C12.2231 1.39362 12.8867 1.29071 13.4845 1.29071C14.4808 1.29071 15.2947 1.57604 15.8566 2.13856C16.7563 3.0382 16.947 4.5832 16.3937 6.48756C15.8018 8.52586 14.4415 10.683 12.5632 12.562C9.94941 15.1771 6.8127 16.6998 4.50967 16.6998C3.53911 16.6998 2.71639 16.4293 2.14341 15.856Z" />

  <defs>
    <linearGradient id={gradientId} x1="0" y1="9" x2="18" y2="9" gradientUnits="userSpaceOnUse">
      <stop stop-color={color1} />
      <stop offset="1" stop-color={color2} />
    </linearGradient>
  </defs>
</svg>
