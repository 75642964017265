<script>
  export let scale = 1;
  export let zoom = 1;
  export let color = "#323232";
  export let fill = "transparent";
</script>

<svg width={18 * scale} height={18 * scale} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect {fill} width="100%" height="100%" />
  <path fill={color} transform="scale({zoom})" transform-origin="50% 50%" d="M0 6.99996V11C0 11.55 0.45 12 1 12H4L7.29 15.29C7.92 15.92 9 15.47 9 14.58V3.40996C9 2.51996 7.92 2.06996 7.29 2.69996L4 5.99996H1C0.45 5.99996 0 6.44996 0 6.99996ZM13.5 8.99996C13.5 7.22996 12.48 5.70996 11 4.96996V13.02C12.48 12.29 13.5 10.77 13.5 8.99996ZM11 1.44996V1.64996C11 2.02996 11.25 2.35996 11.6 2.49996C14.18 3.52996 16 6.05996 16 8.99996C16 11.94 14.18 14.47 11.6 15.5C11.24 15.64 11 15.97 11 16.35V16.55C11 17.18 11.63 17.62 12.21 17.4C15.6 16.11 18 12.84 18 8.99996C18 5.15996 15.6 1.88996 12.21 0.599963C11.63 0.369963 11 0.819963 11 1.44996V1.44996Z" />
</svg>
