<script>
  export let scale = 1;
  export let color = "#323232";
  export let circular = true;
</script>

{#if circular}
  <svg width={40 * scale} height={40 * scale} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={color} d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM23.76 19.08L15.26 27.58C14.48 28.36 13.22 28.36 12.44 27.58C11.66 26.8 11.66 25.54 12.44 24.76L20.94 16.26L18.4 13.72C17.76 13.08 18.22 12 19.1 12H26.98C27.54 12 27.98 12.44 27.98 13V20.88C27.98 21.78 26.9 22.22 26.28 21.58L23.76 19.08Z" />
  </svg>
{:else}
  <svg width={14 * scale} height={14 * scale} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={color} d="M30 32H6C4.9 32 4 31.1 4 30V6C4 4.9 4.9 4 6 4H16C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0H4C1.78 0 0 1.8 0 4V32C0 34.2 1.8 36 4 36H32C34.2 36 36 34.2 36 32V20C36 18.9 35.1 18 34 18C32.9 18 32 18.9 32 20V30C32 31.1 31.1 32 30 32ZM22 2C22 3.1 22.9 4 24 4H29.18L10.92 22.26C10.14 23.04 10.14 24.3 10.92 25.08C11.7 25.86 12.96 25.86 13.74 25.08L32 6.82V12C32 13.1 32.9 14 34 14C35.1 14 36 13.1 36 12V2C36 0.9 35.1 0 34 0H24C22.9 0 22 0.9 22 2Z" />
  </svg>
{/if}
