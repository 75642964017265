<script>
  export let scale = 1;
  export let color = "#323232";
  export let seconds = 10;
</script>

<svg width={40 * scale} height={40 * scale} viewBox="-5 -5 35 39" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill={color} d="M12.3035 28.3333C9.12574 28.3333 6.39796 27.2778 4.12019 25.1667C1.84241 23.0556 0.581297 20.4444 0.336852 17.3333C0.31463 17.0667 0.397963 16.8333 0.586852 16.6333C0.775741 16.4333 1.01463 16.3333 1.30352 16.3333C1.57019 16.3333 1.79796 16.4278 1.98685 16.6167C2.17574 16.8056 2.29241 17.0444 2.33685 17.3333C2.5813 19.8889 3.64796 22.0278 5.53685 23.75C7.42574 25.4722 9.6813 26.3333 12.3035 26.3333C15.0813 26.3333 17.4424 25.3611 19.3869 23.4167C21.3313 21.4722 22.3035 19.1111 22.3035 16.3333C22.3035 13.5556 21.3591 11.1944 19.4702 9.25C17.5813 7.30555 15.248 6.33333 12.4702 6.33333H11.7369L13.4702 8.06666C13.6702 8.26666 13.7702 8.5 13.7702 8.76666C13.7702 9.03333 13.6702 9.26666 13.4702 9.46666C13.2702 9.66666 13.0369 9.76666 12.7702 9.76666C12.5035 9.76666 12.2702 9.66666 12.0702 9.46666L8.57019 5.96666C8.45907 5.85555 8.3813 5.74444 8.33685 5.63333C8.29241 5.52222 8.27019 5.4 8.27019 5.26666C8.27019 5.13333 8.29241 5.01111 8.33685 4.9C8.3813 4.78889 8.45907 4.67777 8.57019 4.56666L12.1035 1.03333C12.2813 0.855551 12.5035 0.766663 12.7702 0.766663C13.0369 0.766663 13.2702 0.855551 13.4702 1.03333C13.648 1.23333 13.7369 1.46666 13.7369 1.73333C13.7369 2 13.648 2.22222 13.4702 2.4L11.5369 4.33333H12.3035C13.9702 4.33333 15.5313 4.64444 16.9869 5.26666C18.4424 5.88889 19.7146 6.74444 20.8035 7.83333C21.8924 8.92222 22.748 10.1944 23.3702 11.65C23.9924 13.1056 24.3035 14.6667 24.3035 16.3333C24.3035 18 23.9924 19.5611 23.3702 21.0167C22.748 22.4722 21.8924 23.7444 20.8035 24.8333C19.7146 25.9222 18.4424 26.7778 16.9869 27.4C15.5313 28.0222 13.9702 28.3333 12.3035 28.3333Z" />
  <text fill={color} x="36%" y="46%" dominant-baseline="middle" text-anchor="middle">{seconds}</text>
</svg>

<style>
  text {
    font-size: 11.2px;
    font-weight: 600;
  }
</style>
