<script>
  export let scale = 1;
  export let color = "#323232";
  export let seconds = 10;
</script>

<svg width={40 * scale} height={40 * scale} viewBox="-5 -5 35 39" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill={color} d="M12.5 28.3333C10.8333 28.3333 9.27222 28.0167 7.81667 27.3833C6.36111 26.75 5.09444 25.8944 4.01667 24.8167C2.93889 23.7389 2.08333 22.4722 1.45 21.0167C0.816667 19.5611 0.5 18 0.5 16.3333C0.5 14.6667 0.816667 13.1056 1.45 11.65C2.08333 10.1944 2.93889 8.92777 4.01667 7.85C5.09444 6.77222 6.36111 5.91666 7.81667 5.28333C9.27222 4.65 10.8333 4.33333 12.5 4.33333H13.2667L11.3333 2.4C11.1556 2.22222 11.0722 1.99444 11.0833 1.71666C11.0944 1.43888 11.1889 1.21111 11.3667 1.03333C11.5444 0.855551 11.7722 0.766663 12.05 0.766663C12.3278 0.766663 12.5556 0.855551 12.7333 1.03333L16.2333 4.56666C16.4333 4.76666 16.5333 5 16.5333 5.26666C16.5333 5.53333 16.4333 5.76666 16.2333 5.96666L12.7333 9.46666C12.5333 9.66666 12.2944 9.76666 12.0167 9.76666C11.7389 9.76666 11.5111 9.66666 11.3333 9.46666C11.1333 9.26666 11.0389 9.03333 11.05 8.76666C11.0611 8.5 11.1667 8.26666 11.3667 8.06666L13.1 6.33333H12.3333C9.57778 6.33333 7.25 7.30555 5.35 9.25C3.45 11.1944 2.5 13.5556 2.5 16.3333C2.5 19.1111 3.47222 21.4722 5.41667 23.4167C7.36111 25.3611 9.72222 26.3333 12.5 26.3333C15.0778 26.3333 17.3222 25.4722 19.2333 23.75C21.1444 22.0278 22.2222 19.8889 22.4667 17.3333C22.5111 17.0444 22.6278 16.8056 22.8167 16.6167C23.0056 16.4278 23.2333 16.3333 23.5 16.3333C23.7889 16.3333 24.0278 16.4333 24.2167 16.6333C24.4056 16.8333 24.4889 17.0667 24.4667 17.3333C24.2222 20.4667 22.95 23.0833 20.65 25.1833C18.35 27.2833 15.6333 28.3333 12.5 28.3333Z" />
  <text fill={color} x="36%" y="46%" dominant-baseline="middle" text-anchor="middle">{seconds}</text>
</svg>

<style>
  text {
    font-size: 11.2px;
    font-weight: 600;
  }
</style>
