<script>
  export let scale = 1;
  export let color = "#323232";
</script>

<svg width={20 * scale} height={20 * scale} viewBox="-0.6 -0.3 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill={color} d="M1.33332 1.33333C1.14443 1.33333 0.986101 1.26944 0.858323 1.14167C0.730545 1.01389 0.666656 0.855556 0.666656 0.666667C0.666656 0.477778 0.730545 0.319444 0.858323 0.191667C0.986101 0.0638889 1.14443 0 1.33332 0H7.99999C8.18888 0 8.34721 0.0638889 8.47499 0.191667C8.60277 0.319444 8.66666 0.477778 8.66666 0.666667C8.66666 0.855556 8.60277 1.01389 8.47499 1.14167C8.34721 1.26944 8.18888 1.33333 7.99999 1.33333H1.33332ZM1.33332 4C1.14443 4 0.986101 3.93611 0.858323 3.80833C0.730545 3.68056 0.666656 3.52222 0.666656 3.33333C0.666656 3.14444 0.730545 2.98611 0.858323 2.85833C0.986101 2.73056 1.14443 2.66667 1.33332 2.66667H7.99999C8.18888 2.66667 8.34721 2.73056 8.47499 2.85833C8.60277 2.98611 8.66666 3.14444 8.66666 3.33333C8.66666 3.52222 8.60277 3.68056 8.47499 3.80833C8.34721 3.93611 8.18888 4 7.99999 4H1.33332ZM1.33332 6.66667C1.14443 6.66667 0.986101 6.60278 0.858323 6.475C0.730545 6.34722 0.666656 6.18889 0.666656 6C0.666656 5.81111 0.730545 5.65278 0.858323 5.525C0.986101 5.39722 1.14443 5.33333 1.33332 5.33333H5.33332C5.52221 5.33333 5.68055 5.39722 5.80832 5.525C5.9361 5.65278 5.99999 5.81111 5.99999 6C5.99999 6.18889 5.9361 6.34722 5.80832 6.475C5.68055 6.60278 5.52221 6.66667 5.33332 6.66667H1.33332Z" />

  <g transform="translate(0.3 0)">
    <path fill={color} d="M7.13333 10.2C7.01111 10.0778 6.95 9.92222 6.95 9.73333C6.95 9.54444 7.01111 9.38889 7.13333 9.26667L8.4 8L7.13333 6.73333C7.01111 6.61111 6.95 6.45555 6.95 6.26667C6.95 6.07778 7.01111 5.92222 7.13333 5.8C7.25556 5.67778 7.41111 5.61667 7.6 5.61667C7.78889 5.61667 7.94444 5.67778 8.06667 5.8L9.33333 7.06667L10.6 5.8C10.7222 5.67778 10.8778 5.61667 11.0667 5.61667C11.2556 5.61667 11.4111 5.67778 11.5333 5.8C11.6556 5.92222 11.7167 6.07778 11.7167 6.26667C11.7167 6.45555 11.6556 6.61111 11.5333 6.73333L10.2667 8L11.5333 9.26667C11.6556 9.38889 11.7167 9.54444 11.7167 9.73333C11.7167 9.92222 11.6556 10.0778 11.5333 10.2C11.4111 10.3222 11.2556 10.3833 11.0667 10.3833C10.8778 10.3833 10.7222 10.3222 10.6 10.2L9.33333 8.93333L8.06667 10.2C7.94444 10.3222 7.78889 10.3833 7.6 10.3833C7.41111 10.3833 7.25556 10.3222 7.13333 10.2Z" />
  </g>
</svg>
